import ApiService from './ApiService';
import { API_DB_CONFIGURATION } from '../core/CcxEnv';
import DbParameters from '../types/DbParameters';

// constants
const API_URL = API_DB_CONFIGURATION;

export type UpdateDatabaseParametersPayload = {
    parameters: { [key: string]: { value: string } };
};

export default class DatabaseConfigurationService extends ApiService {
    /**
     * Get list of Database parameters.
     */
    static async getDatabaseParameters(
        dataStoreUuid?: string
    ): Promise<DbParameters> {
        const response = await this.request('GET', `${dataStoreUuid}`);
        return new DbParameters(response);
    }

    static async updateDatabaseParameters(
        dataStoreUuid: string,
        payload: UpdateDatabaseParametersPayload
    ) {
        const reqData = { data: payload };
        await this.request('PUT', `${dataStoreUuid}`, reqData);
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
